
import google_play from "../../assets/img/google-play-badge.png";
import accounts from "../../assets/img/index.png";
import account_cutted from "../../assets/img/account_cutted.png";

function Header() {
  return (
    <div className="relative pt-8">
      <div className="flex flex-row -z-10 absolute shape bg-sky-50 w-full h-full -skew-y-3 rounded-b-3xl -translate-y-16 overflow-hidden">
        <div className="basis-1/2 px-5 mb-14">
        </div>
        <div className="realtive basis-1/2 text-center skew-y-3">
          <img src={account_cutted} alt="Add security to your accounts." className="absolute w-72 mx-auto -bottom-5 -z-10" />
        </div>
      </div>
      <div className="flex flex-row justify-center items-center gap-8">
        <div className="basis-1/2 px-5 mb-14">
          <h1 className="font-bold text-4xl mb-3">Administra todas tus <span className="block">contraseñas en una sola app.</span></h1>
          <p className="text-slate-600 text-lg font-light mb-3">Conoce la forma más rápida, segura y fácil de administrar tus contraseñas.</p>
          <a href="https://play.google.com/store/apps/details?id=com.vaulky" target="_blank" rel="noreferrer" className="inline-block mt-3">
            <img src={google_play} alt="Get Vaulky on Google Play" className="w-32 mx-auto" />
          </a>
        </div>
        <div className="realtive basis-1/2 text-center">
          <img src={accounts} alt="Save your passwords locally." className="w-72 mx-auto" />
        </div>
      </div>
    </div>
  );
}

export default Header;
