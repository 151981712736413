import { Home, Privacy, Tos } from './pages';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import {
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <NavBar />
      <div className='container mx-auto'>
        <Routes>
          <Route path="/tos" element={<Tos />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
