import React, { useState } from 'react';

function Faqs() {
  const [faqs] = useState([
    {
      title: '¿Qué es Vaulky?',
      body: 'Vaulky es una aplicación que te permite guardar tus contraseñas de manera segura y fácil haciendo uso de altos estandares de seguridad informática.',
      disclaimer: '¡Nunca más olvidarás una contraseña!',
      links: false,
    },
    {
      title: '¿Dónde se guarda mi información?',
      body: 'Toda tu información y contraseñas se encriptan y se almacenan localmente en tu dispositivo móvil, así ningun dato estará expuesto en internet. Tu información te pertenece en todo momento.',
      disclaimer: null,
      links: false,
    },
    {
      title: '¿Quién puede ver mis contraseñas?',
      body: '¡Solo tú! Todas las contraseñas están encriptadas por poderosas funciones criptográficas que aseguran la información para que solo tú haciendo uso de tu "pin code" puedas accederla.',
      disclaimer: null,
      links: false,
    },
    {
      title: '¿Qué es el "pin code"?',
      body: 'El "pin code" es un código de acceso de 6 dígitos que protege toda tu información. Apuntalo o asegurate de recordarlo, porque es el único medio para poder acceder a tu información.',
      disclaimer: null,
      links: false,
    },
    {
      title: '¿Qué pasa si olvido mi "pin code"?',
      body: 'Si olvidas tu "pin code" perderás el acceso a tu información. Ya que esos 6 dígitos son la única forma de desencriptar tu información y solo tú lo conoces.',
      disclaimer: 'Apuntalo de ser necesario.',
      links: false,
    },
    {
      title: '¿Qué es 2FA o Segundo factor de autenticación?',
      body: 'El 2FA o segundo factor de autenticación es un sistema que agrega a tus cuentas una capa más de seguridad. Así cuando escribas tus credenciales usuario/contraseña se te solicitará un código adicional que se renueva cada 30 segundos.',
      disclaimer: null,
      links: false,
    },
    {
      title: '¿Cómo activo 2FA o Segundo factor de autenticación?',
      body: 'Para activar este sistema debes ir al servicio que quieres proteger y seguir sus pasos para activarlo.',
      disclaimer: null,
      links: true,
    }]
  );
  const [accordion, setAccordion] = useState(0);

  const listFaqs = faqs.map((faq, index) => 
    <div className="rounded-md shadow-md p-5 border border-slate-100 mb-5" key={index.toString}>
      <h4 className="text-lg cursor-pointer" onClick={() => setAccordion(index)}>
        {faq.title}
        <div className="inline-block float-right" onClick={() => setAccordion(index)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      </h4>
      {accordion === index &&
        <p className="text-slate-600 text-base font-light pt-3 mt-3 border-t">
          {faq.body}
        </p>
      }
      {faq.disclaimer && accordion === index &&
        <p className="text-slate-600 text-base font-light">{faq.disclaimer}</p>
      }
      {faq.links && accordion === index &&
        <><a href="https://www.facebook.com/help/148233965247823" target="_blank" rel="noreferrer" className="text-sky-700 mr-2">Facebook</a><a href="https://help.twitter.com/es/managing-your-account/two-factor-authentication" target="_blank" rel="noreferrer" className="text-sky-700 mr-2">Twitter</a><a href="https://help.instagram.com/566810106808145" target="_blank" rel="noreferrer" className="text-sky-700 mr-2">Instagram</a><a href="https://support.google.com/accounts/answer/185839?hl=es-419&co=GENIE.Platform%3DDesktop" target="_blank" rel="noreferrer" className="text-sky-700 mr-2">Gmail</a><a href="https://support.microsoft.com/es-es/account-billing/c%C3%B3mo-utilizar-la-verificaci%C3%B3n-en-dos-pasos-con-su-cuenta-de-microsoft-c7910146-672f-01e9-50a0-93b4585e7eb4" target="_blank" rel="noreferrer" className="text-sky-700 mr-2">Outlook</a></>
      }
    </div>
  );
  return (
    <div className="relative pt-8">
      <div className="flex flex-row justify-center gap-8">
        <div className="realtive basis-5/12">
          <h3 className="font-bold text-3xl mb-5">
            ¿Tienes preguntas?
          </h3>
          <p className="text-slate-500 text-base font-light">Si aún tienes dudas envianos un mensaje para ayudarte.</p>
          <div>
            <a href="mailto:support@vaulky.com">support@vaulky.com</a>
          </div>
        </div>
        <div className="basis-7/12">
          {listFaqs}
        </div>
      </div>
    </div>
  );
}

export default Faqs;
