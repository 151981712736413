import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="container mx-auto my-6 flex flex-row">
      <div className="basis-1/2">
        <p className="text-left text-xs text-slate-500">
          © Vaulky 2022. Todos los derechos reservados.
        </p>
      </div>
      <div className="basis-1/2 text-right">
        <Link to="/tos" className='inline-block py-2 text-center text-xs text-slate-500 hover:text-slate-700'>
          Terminos y condiciones
        </Link>
        <span className="mx-4 text-slate-400">|</span>
        <Link to="/privacy" className='inline-block py-2 text-center text-xs text-slate-500 hover:text-slate-700'>
          Politica de privacidad
        </Link>
      </div>
    </div>
  );
}

export default Footer;
