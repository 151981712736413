import account from "../../assets/img/account.png";

function Features() {
  return (
    <div className="relative pt-8">
      <div className="flex flex-row justify-center items-center gap-8">
        <div className="realtive basis-5/12 text-center">
          <img src={account} alt="Save your passwords locally." className="w-64 mx-auto" />
        </div>
        <div className="basis-7/12">
          <h2 className="font-bold text-3xl mb-3">
            Capacidades únicas.
          </h2>
          <p className="text-slate-500 text-base font-light mb-3">
            Desarrollamos una forma simple y segura para almacenar tu información dentro de tu dispositivo movil sin exponer tu información a riesgos innecesarios en internet.
          </p>
          <div className="grid grid-cols-2 gap-6 mt-8">
            <div className="">
              <h5 className="text-lg">Seguridad</h5>
              <p className="text-slate-600 text-base font-light">Toda la información que guardas en nuestra app se almacena completemente encriptada en tu dispositivo móvil, así prevenimos que tu información este dando vueltas en internet y pueda caer en manos de la persona equivocada.</p>
            </div>
            <div className="">
              <h5 className="text-lg">Fácil y rápido</h5>
              <p className="text-slate-600 text-base font-light">La interfaz de nuestra app te permite agregar tus cuentas de manera rápida y accederlas facilmente atraves de un pincode.</p>
            </div>
            <div className="">
              <h5 className="text-lg">Altos estándares</h5>
              <p className="text-slate-600 text-base font-light">Toda la información que ingresas en nuestra app se encripta usando altos estandares de criptografia para protegerla y así mismo solo tú puedes acceder a ella. ¡Sin excepciones!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
