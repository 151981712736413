import Header from "../components/Home/Header.js";
import Features from "../components/Home/Features.js";
import Faqs from "../components/Home/Faqs.js";
import DownloadApp from "../components/Home/DownloadApp.js";

function Home() {
  return (
    <div>
      <Header />
      <Features />
      <Faqs />
      <DownloadApp />
    </div>
  );
}

export default Home;
