import { Navbar } from 'flowbite-react';
import { Link } from "react-router-dom";
import logo from "../assets/img/logo512.png";

function NavBar() {
  return (
    <Navbar>
      <Link to="/">
        <img
          src={logo}
          className="mr-3 h-6 sm:h-9 inline-block"
          alt="Vaulky Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white align-middle">
          Vaulky
        </span>
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <li>
          <Link to="/tos" className='block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>
            Terms and conditions
          </Link>
        </li>
        <li>
          <Link to="/privacy" className='block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>
            Privacy policy
          </Link>
        </li>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
