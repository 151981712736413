import google_play from "../../assets/img/google-play-badge.png";
import logo_transparent from "../../assets/img/logo_transparent.png";

function DownloadApp() {
  return (
    <div className="relative py-12 my-8 bg-sky-50 rounded-3xl bg-image">
      <div className="flex flex-row justify-center items-center gap-8">
        <div className="basis-2/5">
          <h3 className="font-bold text-2xl mb-3">
            Descarga Vaulky
          </h3>
          <p className="text-slate-600 text-lg font-light mb-3">Conoce la forma más rápida, segura y fácil de administrar tus contraseñas.</p>
          <a href="https://play.google.com/store/apps/details?id=com.vaulky" target="_blank" rel="noreferrer" className="inline-block mt-3">
            <img src={google_play} alt="Get Vaulky on Google Play" className="w-32 mx-auto" />
          </a>
        </div>
        <div className="realtive basis-2/5 text-center">
          <img src={logo_transparent} alt="Descarga Vaulky." className="w-28 mx-auto" />
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
